import React, { useState, useEffect } from "react";
import { CircularProgress } from "@mui/material";
import {
  DataGridPro,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarDensitySelector,
  useGridApiContext,
} from "@mui/x-data-grid-pro";
import { LicenseInfo } from "@mui/x-license-pro";

LicenseInfo.setLicenseKey(process.env.REACT_APP_LICENSE_KEY);

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarDensitySelector />
    </GridToolbarContainer>
  );
}

function getJson(x) {
  return fetch(x, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    });
}

const LogTable = ({ Flaskstring, onAlgorithmCompleted }) => {
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [shouldFetchData, setShouldFetchData] = useState(true);

  useEffect(() => { 
    setIsLoading(false);
    onAlgorithmCompleted();
  }, [onAlgorithmCompleted]);

  useEffect(() => {
    if (shouldFetchData) {
      setIsLoading(true);
      getJson(Flaskstring)
        .then((mainData) => {
          if (mainData) {
            // Update columns based on the main data header
            const updatedColumns = mainData.header.map((col) => ({ ...col }));
            setColumns(updatedColumns);
            setRows(mainData.table);

            // No need to handle CVE data here
          } else {
            // Handle the case where the main API call fails
            setColumns([]);
            setRows([]);
          }
          setIsLoading(false);
          setShouldFetchData(false);
          if (onAlgorithmCompleted) {
            onAlgorithmCompleted();
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setIsLoading(false);
          setShouldFetchData(false);
          // Handle error state as needed
        });
    }
  }, [Flaskstring, shouldFetchData, onAlgorithmCompleted]);

  useEffect(() => {
    setShouldFetchData(true);
  }, [Flaskstring]);

  return (
    <div style={{ height: "100%", width: "100%", textAlign: "center" }}>
      {isLoading ? (
        <CircularProgress color="secondary" />
      ) : (
        <DataGridPro
          rows={rows}
          columns={columns}
          pagination
          pageSizeOptions={[100]}
          disableRowSelectionOnClick
          sx={{
            "& .MuiDataGrid-columnHeaderTitle": {
              fontWeight: "bold",
            },
            "& .MuiButton-text": {
              color: "emphasis.main",
            },
            "& .MuiFormLabel-root": {
              color: "yellow",
            },
          }}
          slots={{
            toolbar: CustomToolbar,
          }}
        />
      )}
    </div>
  );
};

export default LogTable;
