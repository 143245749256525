import React, { useEffect, useState } from "react";

import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

import { Button } from "@mui/material";

export default function Header(token) {
  const [open, setState] = useState(false);

  // if token is not null, user is logged in, use state
  const [isLoggedIn, setIsLoggedIn] = useState(token.props ? true : false);
  
  useEffect(() => {
    setIsLoggedIn(token.props ? true : false);
  }, [token]);

  const handleLogout = () => {
    // clear token
    sessionStorage.clear();
    // route to login page
    window.location.href = "/";
    setIsLoggedIn(false);
  };

  const buttonStyles = {
    color: "black",
    borderColor: "black",
    "&:hover": {
      backgroundColor: "black",
      color: "white",
    },
  };



  return (
    <AppBar position="static" elevation={0}>
      <Toolbar>
        <Typography
          variant="h6"
          component="div"
          sx={{ flexGrow: 1, secondary: true }}
        >
          NUS-NCS Joint Lab for Cybersecurity
        </Typography>
        {isLoggedIn ? (
          <Button
            style={buttonStyles}
            variant="outlined"
            onClick={handleLogout}
          >
            Log Out
          </Button>
        ) : null}
      </Toolbar>
    </AppBar>
  );
}
