import React from "react";
import { Box, Typography, IconButton, Button, Divider } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DetailedView from "./DetailedView";
import { styled } from '@mui/material/styles';

const StyledBox = styled(Box)(({ theme }) => ({
  width: 300,
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(3),
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  position: "relative",
  overflow: "auto",
  maxHeight: "100%", // Ensures the sidebar content is scrollable if it exceeds the viewport height
}));

const CloseButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  right: theme.spacing(1),
  top: theme.spacing(1),
  color: theme.palette.grey[500],
}));

const InterfaceInfo = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2),
  '& > div': {
    marginTop: theme.spacing(1),
  },
}));

const Sidebar = ({ node, onClose, softwareData, onNodeSelect }) => {
  return (
    <StyledBox>
      <CloseButton aria-label="close" onClick={onClose}>
        <CloseIcon />
      </CloseButton>
      <Typography variant="h6" gutterBottom component="div">
        Device Details
      </Typography>
      <Typography variant="body2"><strong>IP Address:</strong> {node.ipaddress}</Typography>
      <Typography variant="body2"><strong>ID:</strong> {node.id}</Typography>
      <Typography variant="body2"><strong>Label:</strong> {node.label}</Typography>
      <Divider sx={{ my: 2 }} />
      {node.physicalInterfaces && node.physicalInterfaces.map((Interface, index) => (
        <InterfaceInfo key={index}>
          <Typography variant="body2"><strong>Connection Name:</strong> {Interface.Connection_Name}</Typography>
          <Typography variant="body2"><strong>IP Address:</strong> {Interface.IPv4_Address}</Typography>
          <Typography variant="body2"><strong>MAC Address:</strong> {Interface.MAC_Address}</Typography>
        </InterfaceInfo>
      ))}
      <Button
        variant="outlined"
        onClick={() => onNodeSelect(node)}
        sx={{ mt: 2, display: 'block', width: '100%', color: 'black', outline: '1px solid #000' }}
      >
        View Details
      </Button>
    </StyledBox>
  );
};

export default Sidebar;
