import React, { useState } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Amplify } from 'aws-amplify';

import Header from './components/Header';
import Login from './components/Login';
import ContentTab from './components/ContentTab';

import './App.css';


function App() {
  const APIString = "https://lafidhspbd.execute-api.ap-southeast-1.amazonaws.com/v2"
  // Ideally rotate API key every month
  const APIKey = process.env.REACT_APP_LOGIN_API_KEY;
  const myPalette = {
    primary: {
      main: "#f5f5f5", //white
      dark: "#9e9e9e", //grey
      ddark: "#424242" //dark grey
    },
    secondary: {
      main: "#003D7C", //tab blue
      light: "#0f5a9b", //light blue
      llight: "#227bbe", //very light blue
    },
    emphasis: {
      main: "#65a9d8" //tab indicator blue
    }
  }

  const theme = createTheme({
    palette: myPalette,
    typography: {
      fontFamily: "Tahoma"
    }
  });

  function useToken() {
    const getToken = () => {
      const tokenString = sessionStorage.getItem('token');
      var userToken;
      try {
        userToken = JSON.parse(tokenString).token;
      } catch (err) {
        userToken = "";
      }
      return userToken;
    };

    const [token, setToken] = useState(getToken());

    const saveToken = userToken => {
      sessionStorage.setItem('token', JSON.stringify(userToken));
      setToken(userToken);
    };

    return {
      token,
      setToken: saveToken
    }
  }
  const { token, setToken } = useToken();


  if (!token) {
    return (
      <ThemeProvider theme={theme}>
        <Header/>
        <Login props={{
          setToken: setToken,
          APIString: APIString,
          APIKey: APIKey
        }} />
      </ThemeProvider>
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <Header props={token}/>
      <ContentTab props={{
        myPalette: myPalette,
        APIString: APIString,
        APIKey: APIKey
      }}/>
    </ThemeProvider>
  );
}

export default App;