import React from 'react';

import { Divider } from "@mui/material";

export default function ContentTabDivider({props}) {
  return <Divider
    orientation="vertical"
    style={{
      height: 50,
      alignSelf: "center",
      backgroundColor: props.secondary.light,
      width: "1px"
    }}
  />
}
