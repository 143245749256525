import React, { useState } from "react";

import { Tab, Box, Grid, Typography } from "@mui/material";
import { TabPanel, TabList, TabContext } from "@mui/lab";

import ContentTabDivider from "./ContentTabDivider";
import NewgraphsPanel from "./Dashboards/NewgraphsPanel";
import VulnerabilityDashboard from "./Dashboards/VulnerabilitiesDashBoard";
import AnsiblePanel from "./Dashboards/AnsiblePanel";
import NewlogsPanel from "./Dashboards/NewlogsPanel";
import CytoscapeComponent from "./Graph/CytoscapeComponent";
import RiskDashboard from "./Dashboards/RiskDashboard";
import LogsVulpipeline from "./Dashboards/LogsVulpipeline";

const ContentTab = ({ props }) => {
  const [value, setValue] = useState("logs"); // the selectable values of the content tab

  const [displaytable, setDisplayTable] = useState(null);
  const [uploadfilename, setUploadFileName] = useState("");
  const [uploadfilepath, setUploadFilePath] = useState("");
  const [currentnodes, setCurrentNodes] = useState(null);
  const [currentedges, setCurrentEdges] = useState(null);
  const [currentsubnodes, setCurrentSubNodes] = useState(null);
  const [currentsubedges, setCurrentSubEdges] = useState(null);
  const [runButtonClicked, setRunButtonClicked] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("");
  const [filterModel, setFilterModel] = useState({ items: [] });
  const [selectedCVE, setSelectedCVE] = useState(null);

  const handleChange = (event, newValue) => {
    /*if (newValue === "newgraphs" && !runButtonClicked) {
      // If user clicks on "Graphs" tab without clicking "Run" button, do nothing
      return;
    }*/
    setSelectedCVE(null);
    setValue(newValue);
    if (newValue === "logs") {
      setDisplayTable(null);
    }
  };
  const handleRunButton = (input) => {
    setRunButtonClicked(input);

    // Other logic you want to perform when the "Run" button is clicked
  };

  const handleCVEClick = (cve) => {
    setSelectedCVE(cve);
    setValue("vulnerability"); // Change to the 'vulnerability' tab
  };

  const myStyles = props.myPalette;
  const tabTitleStyle = { color: myStyles.primary.dark, fontSize: 16 };

  // a function that updates the const value to input. used for pages with buttons that redirect to another tab eg threats and graph tab
  const handleInPanelToggle = (input) => {
    setValue(input);
  };

  const handleDisplayTable = () => {
    setDisplayTable("display");
  };

  return (
    <TabContext value={value}>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          backgroundColor: myStyles.secondary.main,
        }}
      >
        <TabList
          onChange={handleChange}
          aria-label="Different graph tabs"
          TabIndicatorProps={{
            style: { backgroundColor: myStyles.emphasis.main, height: 4 },
          }}
        >
          {/* <Tab label="Ansible" value="ansible" sx={tabTitleStyle} />
          <ContentTabDivider props={myStyles} />*/}

         {/* <Tab
            label="Input Assets"
            value="testbedassets"
            sx={tabTitleStyle}
          />
          <ContentTabDivider props={myStyles} />  */}



          <Tab label="Input Logs" value="logs" sx={tabTitleStyle} />
          <ContentTabDivider props={myStyles} />
          
          {/* <Tab label="Network Map" value="graph" sx={tabTitleStyle} />
          <ContentTabDivider props={myStyles} /> */}

          <Tab label="Vulnerability" value="vulnerability" sx={tabTitleStyle} />
          <ContentTabDivider props={myStyles} />

          {/* <Tab label="Risk Scenerio" value="riskscenerio" sx={tabTitleStyle} />
          <ContentTabDivider props={myStyles} />

          <Tab label="Mitigation" value="mitigation" sx={tabTitleStyle} />
          <ContentTabDivider props={myStyles} /> */}

     
          {/* <Tab label="Graph" value="graph" sx={tabTitleStyle} />
          <ContentTabDivider props={myStyles} /> */}
        </TabList>
      </Box>

      {/* <TabPanel value="ansible">
        <AnsiblePanel />
      </TabPanel>*/}
{/* 
      <TabPanel value="logs">
        <NewlogsPanel
          props={{
            handledisplaytable: handleDisplayTable,
            handleinpagetoggle: handleInPanelToggle,
            currentfilename: uploadfilename,
            currentfilepath: uploadfilepath,
            handleRunButton: handleRunButton,
            handlefilename: setUploadFileName,
            handlefilepath: setUploadFilePath,
          }}
          onCVEClick={(cve) => handleCVEClick(cve)}
        />
      </TabPanel>  */}

      {/* <TabPanel value="newgraphs" style={{ height: "78%" }}>
        <Grid container style={{ display: "flex", height: "100%" }}>
          <Grid container style={{ display: "flex", height: "100%" }}>
            <Typography // opening typography tab is <Typography ending typography tab is </Typography>
              variant="h5"
              component="div"
              sx={{ flexGrow: 1, secondary: true, marginBottom: "12px" }}
            >
              Network Map
              <Typography
                style={{ fontSize: "13px" }}
                sx={{ marginTop: "12px" }}
              >
                Mouse over graph edges to see the details.
              </Typography>
            </Typography>
            <NewgraphsPanel
              props={{
                currentfilename: uploadfilename,
                currentfilepath: uploadfilepath,
                currentnodes: currentnodes,
                currentedges: currentedges,
                currentsubnodes: currentsubnodes,
                currentsubedges: currentsubedges,
                handlecurrentnodes: setCurrentNodes,
                handlecurrentedges: setCurrentEdges,
                handlecurrentsubnodes: setCurrentSubNodes,
                handlecurrentsubedges: setCurrentSubEdges,
                handleRunButton: handleRunButton,
              }}
            />
          </Grid>
        </Grid>
      </TabPanel> */}

      <TabPanel value="vulnerability">
        <VulnerabilityDashboard selectedCVE={selectedCVE} />
      </TabPanel>

      {/* <TabPanel value="riskscenerio">
        <RiskDashboard/>
      </TabPanel> */}

      <TabPanel value="logs">
        <LogsVulpipeline onCVEClick={(cve) => handleCVEClick(cve)}/>
      </TabPanel>


      {/* <TabPanel value="graph">
        <CytoscapeComponent onCVEClick={(cve) => handleCVEClick(cve)}/>
      </TabPanel> */}

    </TabContext>
  );
};

export default ContentTab;
