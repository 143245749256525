import React, { useState } from 'react';

import { Container, Box, TextField, Typography, Button } from '@mui/material';


const Login = ({props}) => {
  const [username, setUserName] = useState();
  const [password, setPassword] = useState();
  const [invalidInputs, setInvalidInputs] = useState(false);

  async function loginUser(credentials) {
    return fetch(`${props.APIString}/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-API-Key': props.APIKey
      },
      body: JSON.stringify(credentials)
    })
    .then(
      async res => {
        if (res.status == 422) {
          setInvalidInputs(true);

        } else if (!res.ok) {
          const text = await res.text();
          throw new Error(text);

        } else {
          return res.json();
        }
      })
      .catch(err => {
      });
    }

  const handleSubmit = async e => {
    e.preventDefault();
    const token = await loginUser({
      "userName": username,
      "password": password
    });
    props.setToken(token);
  }

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="username"
            label="Username"
            name="username"
            autoFocus
            onChange={e => setUserName(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            onChange={e => setPassword(e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Submit
          </Button>
        </Box>
        { invalidInputs && <Typography color="red">
            Incorrect username or password.
          </Typography>
        }
      </Box>
    </Container>
  );
};

export default Login;
