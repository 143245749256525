import React, { useEffect, useState } from "react";
import { Pie } from "react-chartjs-2";
import { Box, Typography, Paper, Grid } from "@mui/material";
import "chart.js/auto";
import BugReportIcon from "@mui/icons-material/BugReport";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import InfoIcon from "@mui/icons-material/Info";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import CVESummaryComponent from "./CVESummaryComponent";
import dataSet from "../Graph/data.json";
import cveData from "./cve_data.json";

const VulnerabilityDashboard = ({ selectedCVE }) => {
  const [severityCounts, setSeverityCounts] = useState({
    Critical: 0,
    High: 0,
    Medium: 0,
    Low: 0,
  });

  const [impactSeverityCounts, setImpactSeverityCounts] = useState({
    Critical: 0,
    High: 0,
    Medium: 0,
    Low: 0,
  });

  const [exploitabilityCounts, setExploitabilityCounts] = useState({
    Critical: 0,
    High: 0,
    Medium: 0,
    Low: 0,
  });

  const [pieData1, setPieData1] = useState({
    labels: ["Critical", "High", "Medium", "Low"],
    datasets: [
      {
        data: [0, 0, 0, 0], // Placeholder data
        backgroundColor: ["#FF6384", "#36A2EB", "#4BC0C0", "#FFCE56"],
        hoverBackgroundColor: ["#FF6384", "#36A2EB", "#4BC0C0", "#FFCE56"],
      },
    ],
  });

  const [pieData2, setPieData2] = useState({
    labels: ["Critical", "High", "Medium", "Low"],
    datasets: [
      {
        data: [0, 0, 0, 0], // Placeholder data
        backgroundColor: ["#FF6384", "#36A2EB", "#4BC0C0", "#FFCE56"],
        hoverBackgroundColor: ["#FF6384", "#36A2EB", "#4BC0C0", "#FFCE56"],
      },
    ],
  });

  const [pieDataImpactScore, setPieDataImpactScore] = useState({
    labels: ["Critical", "High", "Medium", "Low"],
    datasets: [
      {
        data: [0, 0, 0, 0], // Placeholder data
        backgroundColor: ["#FF6384", "#36A2EB", "#4BC0C0", "#FFCE56"],
        hoverBackgroundColor: ["#FF6384", "#36A2EB", "#4BC0C0", "#FFCE56"],
      },
    ],
  });

  const [pieDataExploitability, setPieDataExploitability] = useState({
    labels: ["Critical", "High", "Medium", "Low"],
    datasets: [
      {
        data: [0, 0, 0, 0], // Placeholder data
        backgroundColor: ["#FF6384", "#36A2EB", "#4BC0C0", "#FFCE56"],
        hoverBackgroundColor: ["#FF6384", "#36A2EB", "#4BC0C0", "#FFCE56"],
      },
    ],
  });

  // Example data for the indicators
  const indicators = [
    {
      label: "Identified",
      // count of all vulnerabilities
      value: pieData1.datasets[0].data.reduce((a, b) => a + b, 0),
      icon: <BugReportIcon />,
    },
    {
      label: "Confirmed",
      value: pieData1.datasets[0].data.reduce((a, b) => a + b, 0),
      icon: <VerifiedUserIcon />,
    },
    {
      label: "High",
      value: pieData1.datasets[0].data[1],
      icon: <PriorityHighIcon style={{ color: "red" }} />,
    },
    {
      label: "Critical",
      value: pieData1.datasets[0].data[0] + pieData2.datasets[0].data[0],
      icon: <NewReleasesIcon style={{ color: "red" }} />,
    },
  ];

  const lowerindicators = [
    {
      label: "Medium",
      value: pieData1.datasets[0].data[2] + pieData2.datasets[0].data[2],
    },
    {
      label: "Low",
      value: pieData1.datasets[0].data[3] + pieData2.datasets[0].data[3],
    },
    {
      label: "Best Practice",
      value: pieData1.datasets[0].data[4] + pieData2.datasets[0].data[4] || 0,
      icon: <StarBorderIcon style={{ color: "blue" }} />,
    },
    {
      label: "Information",
      value: pieData1.datasets[0].data[5] + pieData2.datasets[0].data[5] || 0,
      icon: <InfoIcon style={{ color: "blue" }} />,
    },
  ];

  const chartOptions = {
    plugins: {
      legend: {
        display: true, // Set this to false if you don't want to show the legend
        position: "right", // Position of the legend ('top', 'bottom', 'left', 'right')
      },
    },
  };

  const scoreToSeverity = (score) => {
    if (score >= 0.1 && score <= 3.9) {
      return "Low";
    } else if (score >= 4.0 && score <= 6.9) {
      return "Medium";
    } else if (score >= 7.0 && score <= 8.9) {
      return "High";
    } else if (score >= 9.0 && score <= 10.0) {
      return "Critical";
    } else {
      return "Unknown"; // For any score that does not fit the given ranges
    }
  };

  // Adjust risk indicator according to the highest severity

  const riskIndicator = {
    label: "Risk Level",
    // Highest count of severity
    value: Object.keys(severityCounts).reduce((a, b) => {
      return severityCounts[a] > severityCounts[b] ? a : b;
    }),
    // Color based on highest count of severity
    color:
      Object.keys(severityCounts).reduce((a, b) => {
        return severityCounts[a] > severityCounts[b] ? a : b;
      }) === "Critical"
        ? "#FF6384"
        : "High"
        ? "#36A2EB"
        : "Medium"
        ? "#4BC0C0"
        : "Low"
        ? "#FFCE56"
        : "Information",
  };
  // const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  let vulnerabilityNodesCollection = [];

  const storedData = sessionStorage.getItem("vulnData");
  // const storedColumns = sessionStorage.getItem('vulnColumns');
  // console.log(storedData);

  // Object.keys(dataSet).forEach((nodeKey) => {
  //   const nodeData = dataSet[nodeKey];
  //   // This first level gets us to each "node" in your dataset
  //   Object.keys(nodeData).forEach((subNodeKey) => {
  //     const subNodeData = nodeData[subNodeKey]["VulnerabilityNodes"];
  //     // Loop through the subNodeData and push it to the collection
  //     Object.keys(subNodeData).forEach((vulnKey) => {
  //       vulnerabilityNodesCollection.push(subNodeData[vulnKey]["cve_id"]);
  //     });
  //   });
  // });
  const uniqueCVEs = {}; // Object to store unique CVEs

  if (storedData) {
    const parsedData = JSON.parse(storedData);

    // Loop through each object in the array
    parsedData.forEach((host) => {
      // Access the "filtered_cve" field within each object
      const filteredCVE = host.filtered_cve;

      if (Array.isArray(filteredCVE)) {
        // Loop through the filtered CVE array
        filteredCVE.forEach((cve) => {
          // Add the CVE to the uniqueCVEs object as a key (to ensure uniqueness)
          uniqueCVEs[cve] = true;
        });
      }
    });
  } else {
  }

  // Convert the keys of uniqueCVEs object to an array
  const uniqueCVEArray = Object.keys(uniqueCVEs);

  // Convert the collection to JSON
  const vulnerabilityNodesJSON = JSON.stringify(uniqueCVEArray);

  useEffect(() => {
    const storedNodes = vulnerabilityNodesJSON;
    if (storedNodes) {
      const parsedData = JSON.parse(storedNodes);

      const processCVEs = async (cveArray) => {
        for (const cve of cveArray) {
          await fetchApiAndStoreData(cve.trim());
        }
      };

      const processAllHosts = async () => {
        await processCVEs(parsedData);
      };

      processAllHosts().catch(console.error);
    }
  }, []);

  const apiKey = process.env.REACT_APP_NIST_API_KEY;

  const fetchApiAndStoreData = async (cveLabel) => {
    // Use a unique storage key for each CVE label
    const storageKey = `cveInfo_${cveLabel}`;

    try {
      // Check for cached data first
      const cachedData = localStorage.getItem(storageKey);
      //convert to json
      const dataJson = JSON.parse(cveData[cveLabel]["cvss"]);
      const convert = JSON.stringify(dataJson);

      updateSeverityCounts(convert);

      // if (cachedData) {
      //   const dataJson = JSON.parse(cachedData);
      //   updateSeverityCounts(dataJson);
      //   return; // Return early to avoid fetching data again
      // }

      // // If no cached data, fetch from the API
      // const response = await fetch(`https://api.vulpipe.com/get_cve_info/${cveLabel}?apiKey=${apiKey}`);
      // if (!response.ok) throw new Error('Network response was not ok');
      // const data = await response.json();

      // // Convert String to JSON
      // const dataJson = JSON.parse(data["cvss"]);

      // // Cache the fetched data for future use
      // localStorage.setItem(storageKey, JSON.stringify(dataJson));

      // updateSeverityCounts(dataJson);
    } catch (error) {
      console.error("Error fetching data for:", cveLabel, error);
    }
  };

  // Refactor the code that updates the state based on the fetched data into a separate function
  const updateSeverityCounts = (dataJson) => {
    dataJson = JSON.parse(dataJson);
    const severity =
      dataJson.cve.metrics.cvssMetricV31[0].cvssData.baseSeverity;
    const impactScore = dataJson.cve.metrics.cvssMetricV31[0].impactScore;
    const exploitabilityScore =
      dataJson.cve.metrics.cvssMetricV31[0].exploitabilityScore;
    const impactSeverity = scoreToSeverity(impactScore);
    const exploitabilitySeverity = scoreToSeverity(exploitabilityScore);

    setSeverityCounts((prevCounts) => ({
      ...prevCounts,
      [severity.charAt(0).toUpperCase() + severity.slice(1).toLowerCase()]:
        prevCounts[
          severity.charAt(0).toUpperCase() + severity.slice(1).toLowerCase()
        ] + 1,
    }));

    setImpactSeverityCounts((prevCounts) => ({
      ...prevCounts,
      [impactSeverity]: prevCounts[impactSeverity] + 1,
    }));

    setExploitabilityCounts((prevCounts) => ({
      ...prevCounts,
      [exploitabilitySeverity]: prevCounts[exploitabilitySeverity] + 1,
    }));
  };

  useEffect(() => {
    setPieData1((prevData) => ({
      ...prevData,
      datasets: [
        {
          ...prevData.datasets[0],
          data: Object.values(severityCounts),
        },
      ],
    }));
    setPieData2((prevData) => ({
      ...prevData,
      datasets: [
        {
          ...prevData.datasets[0],
          data: Object.values(severityCounts),
        },
      ],
    }));
  }, [severityCounts]);

  useEffect(() => {
    setPieDataImpactScore((prevData) => ({
      ...prevData,
      datasets: [
        {
          ...prevData.datasets[0],
          data: [
            impactSeverityCounts.Critical,
            impactSeverityCounts.High,
            impactSeverityCounts.Medium,
            impactSeverityCounts.Low,
          ],
        },
      ],
    }));
  }, [impactSeverityCounts]);

  useEffect(() => {
    setPieDataExploitability((prevData) => ({
      ...prevData,
      datasets: [
        {
          ...prevData.datasets[0],
          data: [
            exploitabilityCounts.Critical,
            exploitabilityCounts.High,
            exploitabilityCounts.Medium,
            exploitabilityCounts.Low,
          ],
        },
      ],
    }));
  }, [exploitabilityCounts]);

  if (selectedCVE) {
    return (
      <Box sx={{ flexGrow: 1, padding: 2 }}>
        <CVESummaryComponent cve={selectedCVE} />
      </Box>
    );
  } else {
    return (
      <Box sx={{ flexGrow: 1, padding: 2 }}>
        <Grid container spacing={2}>
          {/* Main Indicators Grid (Larger) */}
          <Grid item xs={12} md={8}>
            {/* Indicators */}
            <Paper elevation={3} sx={{ padding: 2, marginBottom: 2 }}>
              <Typography variant="h6" gutterBottom>
                Indicators
              </Typography>
              <Grid container spacing={2}>
                {indicators.map((indicator, index) => (
                  <Grid
                    key={index}
                    item
                    xs={6}
                    sm={3}
                    sx={{ textAlign: "center" }}
                  >
                    <Paper
                      elevation={3}
                      sx={{
                        padding: 4,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100%",
                      }}
                    >
                      {indicator.icon}
                      <Typography variant="h6">{indicator.value}</Typography>
                      <Typography variant="body2">{indicator.label}</Typography>
                    </Paper>
                  </Grid>
                ))}
              </Grid>
            </Paper>
          </Grid>

          {/* Additional Grid (Smaller) */}
          <Grid item xs={12} md={4}>
            <Paper elevation={3} sx={{ padding: 2, marginBottom: 2 }}>
              <Box
                sx={{ display: "flex", alignItems: "center", marginBottom: 2 }}
              >
                <Box
                  sx={{
                    width: 20,
                    height: 20,
                    backgroundColor: riskIndicator.color,
                    marginRight: 1,
                  }}
                />
                <Typography>
                  {riskIndicator.label}: {riskIndicator.value}
                </Typography>
              </Box>
              <Typography variant="h6" gutterBottom>
                Additional Information
              </Typography>
              <Grid container spacing={2}>
                {lowerindicators.map((indicator, index) => (
                  <Grid
                    key={index}
                    item
                    xs={12}
                    sm={6}
                    sx={{ textAlign: "center" }}
                  >
                    <Paper
                      elevation={3}
                      sx={{
                        padding: 0,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100%",
                      }}
                    >
                      {indicator.icon}
                      <Typography variant="h6">{indicator.value}</Typography>
                      <Typography variant="body2">{indicator.label}</Typography>
                    </Paper>
                  </Grid>
                ))}
              </Grid>
            </Paper>
          </Grid>

          {/* Pie Charts at the bottom */}
          <Grid item xs={12} md={6}>
            <Paper elevation={3} sx={{ padding: 2 }}>
              <Typography variant="h6" gutterBottom>
                Identified Vulnerabilities
              </Typography>
              <Pie data={pieData1} options={chartOptions} />
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper elevation={3} sx={{ padding: 2 }}>
              <Typography variant="h6" gutterBottom>
                Confirmed Vulnerabilities
              </Typography>
              <Pie data={pieData2} options={chartOptions} />
            </Paper>
          </Grid>

          <Grid item xs={12} md={6}>
            <Paper elevation={3} sx={{ padding: 2 }}>
              <Typography variant="h6" gutterBottom>
                Impact Score
              </Typography>
              <Pie data={pieDataImpactScore} options={chartOptions} />
            </Paper>
          </Grid>

          <Grid item xs={12} md={6}>
            <Paper elevation={3} sx={{ padding: 2 }}>
              <Typography variant="h6" gutterBottom>
                Exploitability Score
              </Typography>
              <Pie data={pieDataExploitability} options={chartOptions} />
            </Paper>
          </Grid>
        </Grid>
      </Box>
    );
  }
};

export default VulnerabilityDashboard;
