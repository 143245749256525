import React, { useEffect, useState } from "react";
import cytoscape from "cytoscape";
import fcose from "cytoscape-fcose";
import computericon from "../../Images/computericon.png";
import firewallicon from "../../Images/firewall.png";
import switchicon from "../../Images/switch.png";
import routericon from "../../Images/router.png";
import Sidebar from "./Sidebar";
import Box from "@mui/material/Box";
import DetailedView from "./DetailedView";
import dataset from "./data.json";
import bluetoothicon from "../../Images/bluetooth.png";
import etherneticon from "../../Images/ethernet.png";

// eslint-disable-next-line
import dblclick from "cytoscape-dblclick";

cytoscape.use(fcose); // Register the fcose layout with cytoscape
cytoscape.use(dblclick);

const osTypeIcons = {
  workstation: computericon,
  switch: switchicon,
  router: routericon,
  firewall: firewallicon,
  bluetooth: bluetoothicon,
  ethernet: etherneticon,
};

const CytoscapeComponent = ({ onCVEClick }) => {
  const [elements, setElements] = useState([]);
  const [selectedNode, setSelectedNode] = useState(null);
  const [graphWidth, setGraphWidth] = useState("100%");
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [currentView, setCurrentView] = useState("graph");
  const [softwareData, setSoftwareData] = useState({});
  const [vulnerabilityData, setVulnerabilityData] = useState({});

  const handleCVEClick = (cve) => {
    onCVEClick(cve);
  };

  const scoreToColor = (score) => {
    if (score < 3) {
      return "#00FF00"; // Green
    } else if (score < 7) {
      return "#FFFF00"; // Yellow
    } else {
      return "#FF0000"; // Red
    }
  };
  const getCVEandEvaluate = (node) => {
    // Example function to simulate getting a CVE score
    if (node.classes().includes("subnet")) {
      return; // Skip coloring for subnet nodes
    }
    const cveScore =  10; // Replace this with actual score retrieval

    const color = scoreToColor(cveScore);
    node.style("background-color", color);
  };

  const convertDataToElements = (data) => {
    let elements = [];
    let ipToSubnetMap = {}; // Maps IP addresses to their subnet for grouping
    let subnetToElementsMap = {}; // Tracks elements (nodes) belonging to subnets
    let processedSubnets = new Set(); // Keeps track of processed subnets to avoid duplicates
    let ipInPhysical = {};


    Object.keys(data.nodes).forEach((nodeKey) => {
      const physicalNode = data.nodes[nodeKey].PhysicalNodes;
      const softwaredata = {...softwareData, [nodeKey] : data.nodes[nodeKey].SoftwareNodes};
      const vulnerabilityData = {[nodeKey] : data.nodes[nodeKey].VulnerabilityNodes};
      console.log('vulnerabiltiyData', vulnerabilityData)
      setSoftwareData(softwaredata);
      setVulnerabilityData(vulnerabilityData);

      // Create a node for the physical device
      elements.push({
        data: {
          id: physicalNode.Hostname,
          label: physicalNode.Hostname,
          physicalInterfaces: physicalNode["Interface Information"],
          image: osTypeIcons[physicalNode["OS Product Type"]],
        },
        classes: "subnet",
      });
      

      // Process each interface in PhysicalNodes
      physicalNode["Interface Information"].forEach((interfaceInfo) => {
        const ip = interfaceInfo.IPv4_Address;
        const subnet = interfaceInfo.IPv4_Subnet || ipToSubnetMap[ip]; // Use mapped subnet or "unknown"

        // Assign IP to subnet map
        ipToSubnetMap[ip] = subnet;

        elements.push({
          data: {
            id: ip,
            label: `${physicalNode.Hostname} (${interfaceInfo.Connection_Name})`,
            ipaddress: ip,
            parent: physicalNode.Hostname,
            image: Object.keys(osTypeIcons).find((key) =>
              interfaceInfo["Connection_Name"]
                .toLowerCase()
                .includes(key.toLowerCase())
            )
              ? osTypeIcons[
                  Object.keys(osTypeIcons).find((key) =>
                    interfaceInfo["Connection_Name"]
                      .toLowerCase()
                      .includes(key.toLowerCase())
                  )
                ]
              : osTypeIcons["default"],
          },
          classes: "interfaceNode",
        });
        ipInPhysical[ip] = physicalNode.Hostname;

        if (subnet) {
          subnetToElementsMap[subnet] = subnetToElementsMap[subnet] || [];
          subnetToElementsMap[subnet].push(ip);
        }
      });
    });

    Object.keys(data.nodes).forEach((nodeKey) => {
      const networkNodes = data.nodes[nodeKey].NetworkNodes || {};

      Object.values(networkNodes).forEach(
        ({ src_ip, dst_ip, src_subnet, dst_subnet, Connection_Name }) => {
          // Process both src and dst IPs
          [
            [src_ip, src_subnet],
            [dst_ip, dst_subnet],
          ].forEach(([ip, subnet]) => {
            if (ip && !ipToSubnetMap[ip]) {
              ipToSubnetMap[ip] = subnet || "unknown"; // Use "unknown" if subnet is not defined
              elements.push({
                data: {
                  id: ip,
                  label: ip,
                  ipaddress: ip,
                  image: osTypeIcons["workstation"],
                  
                },
              });
              if (subnet) {
                subnetToElementsMap[subnet] = subnetToElementsMap[subnet] || [];
                subnetToElementsMap[subnet].push(ip);
              }
            }
          });

          // Create an edge for the connection
          elements.push({
            data: {
              id: `edge-${src_ip}-${dst_ip}`,
              source: src_ip,
              target: dst_ip,
            },
          });
        }
      );
    });

    // Then, create nodes for PhysicalNodes and their interfaces

    // Finally, add subnet nodes based on the elements mapped to each subnet
    Object.entries(subnetToElementsMap).forEach(([subnet, ips]) => {
      if (!processedSubnets.has(subnet)) {
        elements.push({
          data: {
            id: `subnet-${subnet}`,
            label: `Subnet ${subnet}`,
            parent: ipInPhysical[ips],
          },
          classes: "subnet",
        });
        processedSubnets.add(subnet);
      }
      // Ensure each IP node within the subnet has the correct parent assigned
      ips.forEach((ip) => {
        const ipElementIndex = elements.findIndex((el) => el.data.id === ip);
        if (ipElementIndex !== -1) {
          elements[ipElementIndex].data.parent = `subnet-${subnet}`;
        }
      });
    });

    return elements;
  };

  useEffect(() => {
    const elements = convertDataToElements(dataset);
    setElements(elements);
  }, []);

  useEffect(() => {
    if (elements.length > 0 && currentView === "graph") {
      const cy = cytoscape({
        container: document.getElementById("cy"),
        elements: elements,
        style: [
          {
            selector: "node", // Base node styling
            style: {
              "background-color": "#666",
              shape: "ellipse",
              label: "data(label)",
              "text-valign": "top",
              "text-margin-y": -10,
              color: "black",
              "font-size": "12px",
            },
          },
          {
            selector: "node[image]", // Apply this style only to nodes that have an 'image' field
            style: {
              "background-image": "data(image)",
              "background-fit": "contain",
              "background-clip": "none",
            },
          },
          {
            selector: ".physicalNode",
            style: {
              "background-opacity": 0.333,
              "text-valign": "top",
              "text-halign": "center",
              "border-color": "black",
              "width": "100px",
              "height": "100px",
            },
          },
          {
            selector: "node:parent", // Selects compound nodes (e.g., subnets)
            style: {
              "background-opacity": 0.333, // Adjust as needed for your design
              // Do not set 'background-image' here since subnets don't have images
            },
          },

          // Add more styles as needed
        ],
        layout: {
          name: "fcose",
          nodeSeparation: 7000, 
          nodeRepulsion: () => 20000, 
          idealEdgeLength: () => 150, 
        },
      });

      

      cy.nodes().forEach((node) => {
        getCVEandEvaluate(node);
      });

      cy.on("tap", "node", (evt) => {
        setSelectedNode(evt.target.data());
        setGraphWidth("75%"); // Adjust the width to make room for the sidebar
        setIsSidebarOpen(true);
      });

      cy.on("tap", (event) => {
        // If the tap was not on a node, clear the selection and reset the graph width
        if (event.target === cy) {
          setSelectedNode(null);
          setGraphWidth("100%"); // Use full width when no node is selected
          setIsSidebarOpen(false);
        }
      });

      cy.on("zoom", () => {
        const zoomLevel = cy.zoom();
        const labelVisibilityThreshold = 1; // Adjust this threshold for hiding/showing elements
        const baseFontSize = 42; // Large font size for parent labels when zoomed out
        const normalFontSize = 12; // Normal font size for labels

        // Ensure visibility for all elements first, then apply conditional visibility
        cy.edges().style("display", "element"); // Default to making all edges visible

        cy.elements().forEach((el) => {
          if (
            el.isNode() &&
            el.data("parent") &&
            el.data("parent") !== "publicNetwork"
          ) {
            // For nodes within subnets
            el.style(
              "display",
              zoomLevel <= labelVisibilityThreshold ? "none" : "element"
            );
          } else if (
            el.isEdge() &&
            el.data("target") !== "publicNetwork" &&
            el.data("source") !== "publicNetwork"
          ) {
            const sourceParent = el.source().data("parent");
            const targetParent = el.target().data("parent");
            // Hide edges within subnets when zoomed out, shown otherwise

            el.style(
              "display",
              sourceParent &&
                targetParent &&
                zoomLevel <= labelVisibilityThreshold
                ? "none"
                : "element"
            );
          } else {
            // For all other elements
            el.isEdge() && el.style("display", "element");
          }
        });

        cy.nodes().forEach((node) => {
          if (node.isParent() || !node.isChild()) {
            // For parent nodes (subnets) and nodes not within any subnet
            const fontSize =
              zoomLevel <= labelVisibilityThreshold
                ? baseFontSize
                : normalFontSize;
            node.style({
              "font-size": `${fontSize}px`, // Adjust font size based on zoom level
              "text-opacity": 1, // Ensure text is fully opaque
              color: "black", // Set font color to black
            });
          }
        });
      });

      let lastClickTime = 0;
      const doubleClickDelay = 300; // milliseconds

      cy.on("tap", "node", function (event) {
        const currentTime = new Date().getTime();
        if (currentTime - lastClickTime < doubleClickDelay) {
          // Detected a double-click
          const node = event.target;
          const isSubnet = node.data().isSubnet; // Adjust based on how you identify subnets

          if (isSubnet) {
            // Example: Adjust the zoom level and center on the double-clicked node
            cy.animate(
              {
                center: { eles: node },
                zoom: 100, // Example zoom level, adjust according to your needs
              },
              {
                duration: 500, // Animation duration in milliseconds
              }
            );
          }
        } else {
          // This is a single click, or the clicks are too far apart to be considered a double-click
          // You can handle single click actions here if necessary
        }
        lastClickTime = currentTime;
      });

      cy.on("dblclick", "node", function (evt) {
        const node = evt.target;
        // Check if the node is a subnet by some property, e.g., a class
        if (node.hasClass("subnet")) {
          cy.animate(
            {
              center: { eles: node },
              zoom: 1.5, // Adjust this zoom level as needed
            },
            {
              duration: 200,
            }
          );
        }
      });

      return () => {
        cy.destroy();
      };
    }
  }, [elements, graphWidth, currentView]);

  const handleCloseSidebar = () => {
    setIsSidebarOpen(false);
    setGraphWidth("100%");
    setSelectedNode(null);
  };

  const handleNodeSelect = (nodeData) => {
    setSelectedNode(nodeData);
    setCurrentView("detail");
  };
  return (
    <Box sx={{ display: "flex", width: "100%", height: "100%"}}>
      {currentView === "graph" ? (
        <>
          <Box id="cy" sx={{ width: graphWidth, height: 600 }} />
          {isSidebarOpen && selectedNode && (
            <Sidebar
              node={selectedNode}
              onClose={handleCloseSidebar}
              softwareData={softwareData[selectedNode.id]}
              vulnerabilityData={vulnerabilityData[selectedNode.id]}
              onNodeSelect={handleNodeSelect}
            />
          )}
        </>
      ) : (
        <DetailedView
          nodeInfo={selectedNode} // Assuming selectedNode is an object with an id
          softwareData={softwareData[selectedNode.id] ? softwareData[selectedNode.id] : ""}
          vulnerabilityData={vulnerabilityData[selectedNode.id] ? vulnerabilityData[selectedNode.id] : ""}
          onBack={() => setCurrentView("graph")}
          icon={selectedNode.image ? selectedNode.image : undefined}
          handleCVEClick={handleCVEClick}
        />
      )}
    </Box>
  );
};

export default CytoscapeComponent;
