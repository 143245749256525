import React, { useState, useEffect } from 'react';
import { DataGridPro } from '@mui/x-data-grid-pro';

const LogsVulpipeline = ({ onCVEClick }) => {
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [uploadedFileInfo, setUploadedFileInfo] = useState({});

  const updateColumns = (data) => {
    if (data.length > 0) {
      const firstItemKeys = Object.keys(data[0]);
      const dynamicColumns = firstItemKeys.map(key => ({
        field: key,
        headerName: key.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase()),
        width: key === 'filtered_cve' ? 300 : 150,
        renderCell: key === 'filtered_cve' ? renderCVELink : undefined,
      }));
      setColumns(dynamicColumns);
    }
  };
  const renderCVELink = (params) => {
    // Check if params or params.value is undefined or not an array
    if (!params || !Array.isArray(params.value)) {
      return <div>No CVE data available</div>;
    }
  
    return (
      <div>
        {params.value.map((cve, index) => (
          <a key={index} href="#" onClick={(e) => {
            e.preventDefault();
            onCVEClick(cve);
          }} style={{ marginRight: 5 }}>
            {cve}
          </a>
        ))}
      </div>
    );
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
    setUploadSuccess(false);  // Reset upload success state
    setUploadedFileInfo({}); // Reset file info
  };

  const handleUploadFile = async () => {
    if (file) {
      setLoading(true);
      const formData = new FormData();
      formData.append("file", file);
      try {
        const uploadResponse = await fetch("https://t5k1l97k7c.execute-api.ap-southeast-1.amazonaws.com/v1/pushdata", {
          method: "POST",
          body: file,
        });

        if (!uploadResponse.ok) {
          throw new Error("Failed to upload file.");
        }

        const responseJson = await uploadResponse.json();
        setUploadedFileInfo({
          fileName: responseJson.file_name,
          filePath: responseJson.filepath.replace('s3://bucket', '') // Adjust based on actual bucket path returned
        });
        setUploadSuccess(true);
        alert("File uploaded successfully!");
      } catch (error) {
        console.error("Failed to upload file:", error);
        alert("File upload failed.");
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    const storedData = sessionStorage.getItem('vulnData');
    const storedColumns = sessionStorage.getItem('vulnColumns');
    if (data.length === 0 && columns.length === 0 && storedColumns && storedData) {
      setData(JSON.parse(storedData));
      updateColumns(JSON.parse(storedColumns));
    }
  }, [data, columns]);


  const handleFetchData = async () => {
    if (uploadSuccess) {
      setLoading(true);
      try {
        const response = await fetch(`https://t5k1l97k7c.execute-api.ap-southeast-1.amazonaws.com/v1/fetchdata?file_path=${uploadedFileInfo.filePath}&file_name=${uploadedFileInfo.fileName}`, {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const rawData = await response.json();
        const parsedData = rawData.map((item, index) => ({
          ...JSON.parse(item),
          id: index,
        }));
        

        setData(parsedData);
        updateColumns(parsedData);

        // store the data in session storage
        sessionStorage.setItem('vulnData', JSON.stringify(parsedData));
        sessionStorage.setItem('vulnColumns', JSON.stringify(parsedData));

      } catch (error) {
        console.error("Failed to fetch data:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  // Calculate grid height based on content
  const gridHeight = data.length > 0 ? 700 : 300;

  return (
    <div style={{ height: gridHeight, width: '100%' }}>
      <input type="file" onChange={handleFileChange} style={{ marginBottom: 20 }} />
      <button onClick={handleUploadFile} disabled={!file || loading}>
        Upload File
      </button>
      <button onClick={handleFetchData} disabled={!uploadSuccess || loading}>
        Fetch Data
      </button>
      <DataGridPro
        rows={data}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        disableSelectionOnClick
        loading={loading}
        pagination={data.length > 0}
        hideFooter={data.length === 0}
      />
    </div>
  );
};

export default LogsVulpipeline;
