import React, { useEffect, useState } from "react";
import { Card, CardContent, Typography, CircularProgress } from "@mui/material";


const CVESummaryComponent = ({ cve }) => {
  const [cveData, setCveData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true); // Start loading
    fetch(`https://services.nvd.nist.gov/rest/json/cves/2.0?cveId=${cve}`)
      .then((response) => response.json())
      .then((data) => {
        setCveData(data);
        setIsLoading(false); // Stop loading after data is received
      })
      .catch((error) => {
        console.error("Error fetching CVE data:", error);
        setIsLoading(false); // Stop loading if there's an error
      });
  }, [cve]);

  if (isLoading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
        <CircularProgress />
      </div>
    );
  }

  if (!cveData) {
    return <Typography variant="h5" component="h2">Loading CVE data...</Typography>;
  }

  return (
    <Card>
      <CardContent>
        <Typography variant="h5" component="h2">
          CVE: {cveData.vulnerabilities[0].cve.id}
        </Typography>
        <Typography color="textSecondary">Date: {cveData.timestamp}</Typography>
        <Typography variant="body2" component="p">
          Severity: {cveData.vulnerabilities[0].cve.metrics["cvssMetricV31"][0].cvssData
            .baseSeverity}
        </Typography>
        <Typography variant="body2" component="p">
          Score: {cveData.vulnerabilities[0].cve.metrics["cvssMetricV31"][0].cvssData
            .baseScore}
        </Typography>
        <Typography variant="body1">
          Description: {cveData.vulnerabilities[0].cve.descriptions[0].value}
        </Typography>
        <Typography variant="body1">
          Attack Complexity: {cveData.vulnerabilities[0].cve.metrics["cvssMetricV31"][0].cvssData
            .attackComplexity}
        </Typography>
        <Typography variant="body1">
          Privilege Required: {cveData.vulnerabilities[0].cve.metrics["cvssMetricV31"][0].cvssData
            .privilegesRequired}
        </Typography>
        <Typography variant="body1">
          Exploitability Score: {cveData.vulnerabilities[0].cve.metrics["cvssMetricV31"][0].exploitabilityScore}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default CVESummaryComponent;
